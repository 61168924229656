import React from 'react';
export const Banner10DataSource = {
  wrapper: { className: 'banner1' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        textWrapper: { className: 'banner1-text-wrapper' },
        bg: { className: 'bg bg0 kjmdvn38eg6-editor_css' },
        title: {
          className: 'banner1-title kjmb7fl2z69-editor_css',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>盘古优云信息技术有限公司</p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'banner1-content kjmop1u47j-editor_css',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <h2>云计算时代的开天辟地</h2>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        button: { className: 'banner1-button', children: 'Learn More' },
      },
    ],
  },
};
export const Content00DataSource = {
  wrapper: {
    className: 'home-page-wrapper content0-wrapper kjmggalp66g-editor_css',
  },
  page: { className: 'home-page content0 kjmgd6vtfnu-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '产品与服务' }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>专业软件开发服务</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>系统开发，应用开发，小程序开发</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>专业IT运维服务</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>提供互联网企业级别的全局IT运维体系服务</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://cdn1.iconfinder.com/data/icons/business-incubator/64/service-team-assistance-consultant-support-128.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>一站式IT顾问服务</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>拥有各行业IT案例的资深顾问服务</p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Feature40DataSource = {
  wrapper: { className: 'home-page-wrapper content6-wrapper' },
  OverPack: { className: 'home-page content6' },
  textWrapper: { className: 'content6-text', xs: 24, md: 10 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>专业软件开发服务</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>
              <br />
            </p>
          </span>
        ),
      },
    ],
  },
  img: {
    children: 'https://zos.alipayobjects.com/rmsportal/VHGOVdYyBwuyqCx.png',
    className: 'content6-img',
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children:
            'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          className: 'content6-icon',
        },
        title: { className: 'content6-title', children: '技术' },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>
                丰富的技术组件，简单组装即可快速搭建金融级应用，丰富的技术组件，丰富的各行业软件的开发经验。
              </p>
            </span>
          ),
        },
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
        },
        title: { className: 'content6-title', children: '融合' },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>解放业务及技术生产力，用技术力量支撑和实现客户各种需求</p>
            </span>
          ),
        },
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
        },
        title: { className: 'content6-title', children: '开发' },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <p>
                  符合互联网企业的安全可靠、高可用、高性能的服务能力，符合行业要求的安全可靠、高可用、高性能的服务能力。
                </p>
              </span>
            </span>
          ),
        },
      },
    ],
  },
};
export const Content30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>专业的IT运维服务</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>基于强大的资深运维工程师团队</p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>服务器运维</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  Linux及windows平台的专业服务器运维业务，系统/应用/网络/审计等专业服务，助力企业达到SLA
                  99.99%级别
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '云安全' },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>按打造的完整安全体系，全方位保障应用及数据安全。</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '云监控' },
          content: {
            className: 'content3-content',
            children:
              '分布式云环境集中监控，统一资源及应用状态视图，智能分析及故障定位。',
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '分布式中间件' },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  金融级联机交易处理中间件，大规模分布式计算机，单机C10K级并发能力，严格确保服务持续性。
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png',
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: '大数据' },
          content: {
            className: 'content3-content',
            children:
              '一站式、全周期大数据协同工作平台，PB级数据处理、毫秒级数据分析工具。',
          },
        },
      },
    ],
  },
};
export const Feature41DataSource = {
  wrapper: { className: 'home-page-wrapper content6-wrapper' },
  OverPack: { className: 'home-page content6' },
  textWrapper: { className: 'content6-text', xs: 24, md: 10 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>一站式顾问服务</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>
              <br />
            </p>
          </span>
        ),
      },
    ],
  },
  img: {
    children: 'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
    className: 'content6-img',
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children:
            'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          className: 'content6-icon',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <h3>拥有多个不同行业的成功案例</h3>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <p>
                  <br />
                </p>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <h3>资深顾问从业超过15年，经验丰富，提供多行业的专业见解</h3>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <p>、</p>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <h3>
                  由咨询-出具方案-方案实施-项目跟踪管理-后期运营
                  完全一站式的服务形式
                </h3>
                <p>
                  <br />
                </p>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>
                <br />
              </p>
            </span>
          ),
        },
      },
    ],
  },
};
export const Teams10DataSource = {
  wrapper: {
    className: 'home-page-wrapper teams1-wrapper kjmqzfzaec-editor_css',
  },
  page: { className: 'teams1 home-page kjmr12czezs-editor_css' },
  OverPack: { playScale: 0.3, className: 'kjmqzcga4d-editor_css' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '团队成员' }],
  },
  block: {
    className: 'block-wrapper kjmqws2g1pp-editor_css',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'http://www.pangu-cloud.com/images/pic/jet.png',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <span>
                    <p>
                      <b>陈杰辉</b>
                    </p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job kjmsmksufgk-editor_css',
              children: (
                <span>
                  <span>
                    <p>
                      <b>总经理 CIO</b>
                    </p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content kjmsn1j33a-editor_css',
              children: (
                <span>
                  <span>
                    <p>
                      陈杰辉，专注服务器运维，云计算，企业信息化建设。windows/linux管理超过15年，拥有云计算厂家vmware虚拟化认证。擅长各种高可用高并发服务器集群/私有云的搭建及维护。曾管理运维300台以上的服务器集群。拥有多个电商/SNS/出行/金融领域的相关运维服务经验。
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'http://www.pangu-cloud.com/images/pic/zj.png',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <span>
                    <p>
                      <b>周剑</b>
                    </p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job kjmsoa3shim-editor_css',
              children: (
                <span>
                  <span>
                    <p>产品总监/资深顾问</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content kjmsoz4j7r-editor_css',
              children: (
                <span>
                  <p>
                    高级项目管理专家，拥有超过8年的大型系统集成经验。曾担任过多个大型网站及平台的项目负责人和产品负责人，具有丰富的制造业、通信行业、互联网行业的项目管理经验。曾服务上汽通用五菱宝骏、东风柳汽、上海迪士尼乐园、中国移动通信集团、中国南方航空、粒上皇、安联保险
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'http://www.pangu-cloud.com/images/pic/kelta.png',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>何善慷</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job kjmsr1k8kpg-editor_css',
              children: (
                <span>
                  <span>
                    <p>技术总监</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content kjmstn6wblo-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          19年的互联网工作经验。曾参与设计、搭建、运营、管理，知名媒体的资讯平台与电商金融平台等数十个互联网平台。曾运营智慧金融、创新金融平台。曾担任大型企业CTO，全球知名论坛网络安全小组成员。
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~kjmqwmfm48u',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'http://www.pangu-cloud.com/images/pic/joe.png',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>周卓林</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job kjmsu47g19k-editor_css',
              children: (
                <span>
                  <span>
                    <p>首席咨询顾问</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content kjmsuvu8o5h-editor_css',
              children: (
                <span>
                  <p>
                    网易两大创始人之一，立足中国互联网20年，对电子商务、移动互联网、物流运输、冷链管理，尤其对大数据采集、分析、应用与监控有极深的实际研究与行业经验。过往负责产品项目：网易邮箱，大话西游，网易通行证，问道旅游，星星打车，妙购商城等。
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block~kjmshpqd5w',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children:
                'http://www.pangu-cloud.com/images/pic/wh.png',
            },
            {
              name: 'title',
              className: 'teams1-title',
              children: (
                <span>
                  <p>王辉</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'teams1-job kjmsvfc7bsb-editor_css',
              children: (
                <span>
                  <p>网络安全专家</p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content kjmsvt91fnu-editor_css',
              children: (
                <span>
                  <p>
                    &nbsp; &nbsp; &nbsp; &nbsp; 从事信息安全⼯作15年，
                    中国信息安全测评中⼼注册讲师。通过国家注册信息安全讲
                    师（CISI）、国家注册信息安全
                    专业⼈员（CISP）、国家反计算机⼊侵和防病毒研究中⼼信息安全师（INSPC）、国际注册
                    云安全系统认证专家(CCSSP)、国际信息安全主任审核员
                    （ISO27001LA）、国际项⽬管理师（PMP）、国家⼆级信息
                    安全师、软件开发师（J2EE）等专业认证。&nbsp;
                  </p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Footer10DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: { className: 'logo', children: '' },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '产品' },
        childWrapper: {
          children: [
            {
              name: 'link1',
              href: '#',
              children: (
                <span>
                  <p>智能路由</p>
                </span>
              ),
            },
            {
              name: 'link2',
              href: '#',
              children: (
                <span>
                  <p>运维服务</p>
                </span>
              ),
            },
            { name: 'link3', href: '#', children: '参考指南' },
          ],
        },
      },
      {
        name: 'block2',
        xs: 24,
        md: 6,
        className: 'block',
        title: { children: '关于' },
        childWrapper: {
          children: [{ href: 'mailto:jet@pangu-cloud.com', name: 'link1', children: '联系我们' }],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        ©2021 by 盘古优云信息技术有限公司 All Rights 
        Reserved <a href="https://beian.miit.gov.cn/#/Integrated/index">粤ICP备2021005605号-1</a>
      </span>
    ),
  },
};
